@media (max-width: 576px) {
    .home-header {
        padding: 10% 0 3%;
    }

    .gym-text>h3 {
        font-size: 18px;
    }

    .gym-text>p {
        font-size: 12px;
    }

    .operator__link_space {
        font-size: 14px;
    }

    .home-btn .ads-subs>a {
        font-size: 14px;
    }

    .home-btn .ads-subs,
    .home-btn .attendance-number {
        width: 253px;
        height: 90px;
    }

    /* add subs */
    .add-input_body {
        width: 400px;
    }

    /* subscriptions */
    .subs-button>a {
        font-size: 12px;
    }

    .subs-filter_main {
        width: 88%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding: 4% 2%;
    }

    .subs-filter {
        background-color: var(--white-color);
        justify-content: center;
        border-radius: 20px;
        display: flex;
    }

    .subs-date-wrapper,
    .users-filter,
    .classes-users {
        width: 80%;
    }

    .subs-filter_btns {
        width: 80%;
    }

    .search-filter {
        width: 100%;
    }

    .user__link {
        font-size: 12px;
    }

    .package-link {
        font-size: 12px;
    }

    .button-container {
        padding: 0;
        gap: 0;
    }

    .returnBtn>button>span,
    .frozenBtn>button>span {
        font-size: 12px;
    }

    .change-password-header {
        padding: 25px 0;
    }

    /* login */
    .login__body {
        display: flex;
        flex-direction: column;
        gap: 60px;
        width: 100%;
        padding: 5% 4%;
    }

    .login_text>h3 {
        font-size: 16px;
    }
}