@media only screen and (min-width: 992px) and (max-width: 1160px) {

    /* sidebar */

    .sidebar {
        width: 200px;
    }

 

    .home,
    .packages-hero,
    .subscriptions-hero,
    .notifications-hero,
    .class-hero,
    .classlist-hero {
        padding: 6% 3.5% 0;
        left: 200px;
        width: calc(100% - 200px);
    }

    .attendance-count>p {
        font-size: 14px;
    }


    /* subs */
    .subs-button>a {
        font-size: 14px;
        padding: 0 5px;
    }

    .subs-filter_main {
        gap: 30px;
    }

    .subs-filter_main label {
        font-size: 12px;
    }

    .subs-date-wrapper {
        width: 20%;
    }

    .subs-date {
        width: 100%;
    }

    .frozenBtn>button,
    .returnBtn>button {
        padding: 4px 10px;
    }

    .activ-subs_table {
        width: 100%;
    }
    /* Login */
    .login_text >h3 {
        font-size: 20px;
        line-height: 30px;
        width: 85%;
        letter-spacing: .3px;
    }

    .login_form {
        width: 80%;
      }
    /* classlist */
    .classlist-reserve_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .reserve-number_bg {
        width: 18%;
        padding: 2% 2%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}