@media (min-width: 320px) and (max-width: 480px) {
    .home-header {
        padding: 18% 0 3%;
    }

    .gym-text>p {
        font-size: 10px;
    }

    .attendance-count>p {
        font-size: 12px;
    }

    .home-btn .ads-subs>a {
        font-size: 13px;
    }

    .attendance-number .attendance-icon {
        width: 45px;
        height: 45px;
    }

    /* home */
    .home-btn .ads-subs,
    .home-btn .attendance-number {
        width: 243px;
        height: 80px;
    }

    .home .home-btn {
        padding: 8% 0;
    }

    .departure_count__wrapper>h6 {
        padding: 4% 0 1%;
    }

    /* packages */

    .packages-wrapper,
    .subscriptions-wrapper,
    .classlist-wrapper {
        padding-top: 10%;
    }

    .change-password-hero {
        padding: 23% 3.5% 0;
    }

    .change-password_form {
        padding: 15% 2% 2%;
        width: 100%;
    }

    /* Add subs & note */
    .note,
    .add_Subs {
        padding: 30% 3% 0;
    }

    .section_header>h3 {
        font-size: 18px;
    }

    .add-input_body {
        width: 380px;
        padding: 5%;
    }

    .add-input_wrapper {
        padding: 15% 3%;
    }

    .form-group>label {
        font-size: 12px;
        color: var(--grey-color);
        padding-bottom: 1%;
    }

    #exception_data label {
        font-size: 10px;
        padding-bottom: 4%;
    }

    /* Login */
    .login__body {
        gap: 100px;
        width: 100%;
        padding: 10% 4%;
    }
}