@media (max-width:992px) {
    /* sidebar */

    .sidebar {
        width: 160px;
    }

    .sidebar .txt {
        font-size: 15px;
    }

    .nav-link .nav-icon {
        font-size: 18px;
        min-width: 40px;
    }

    .img-text .img-logo img {
        width: 80px;
    }

    .home,
    .packages-hero,
    .subscriptions-hero,
    .notifications-hero,
    .class-hero,
    .note,
    .add_Subs,
    .change-password-hero,
    .classlist-hero {
        padding: 8% 3.5% 0;
        left: 170px;
        width: calc(100% - 170px);
    }

    /* Login */

    .login_text>h3 {
        font-size: 17px;
        line-height: 28px;
        width: 100%;
    }

    .login_form {
        width: 100%;
    }

    .login__body {
        gap: 55px;
    }

    .login__carousel {
        padding: 6% 0;
        height: 580px;
    }

    .carousel-box {
        width: 280px;
        height: 280px;
    }

    .carousel-text {
        padding: 15% 0 0;
    }

    /* HOME */
    .home .home-btn {
        padding: 6% 0;
    }

    .gym-header-right>.notification>a>.fa-bell {
        font-size: 18px;
    }

    .gym-text>p {
        font-size: 15px;
    }

    .attendance-count>p {
        font-size: 15px;
    }

    .custom-tag {
        /* Mobil cihazlarda gösterilecek stil düzenlemeleri */
        font-size: 14px;
        width: 50px;
    }

    /* add subs & note */
    .sidebar.close~.note,
    .sidebar.close~.add_Subs {
        left: 100px;
        width: calc(100% - 100px);
    }

    .add-input_body {
        width: 500px;
        height: auto;
        background: var(--white-color);
        border-radius: 20px;
        padding: 3% 3%;
    }

    .add-input_wrapper {
        padding: 7% 1%;
    }

    .section_header>h3 {
        font-size: 22px;
    }

    .note-textarea {
        width: 100%;
        height: auto;
        padding: 6% 6%;
    }

    .note-input-textarea {
        gap: 45px;
    }

    /* btns */
    .btn-library>button {
        width: 139px;
        height: 45px;
    }

    .btn-library>.cansel {
        font-size: 15px;
    }

    .btn-library>.save {
        font-size: 15px;
    }

    /* modal */
    .information-description>h5 {
        font-size: 15px;
    }

    /* active subs */
    .subs-button>a {
        font-size: 14px;
        padding: 0 5px;
    }

    .subs-filter_main {
        gap: 12px;
    }

    .subs-filter_main label {
        font-size: 12px;
    }

    .subs-date-wrapper {
        width: 30%;
    }

    .subs-date {
        width: 100%;
    }

    .users-filter,
    .classes-users {
        width: 30%;
    }

    /* Frozen and return btn */


    .frozenBtn>button>span {
        font-size: 13px;
    }

    .returnBtn>button>span {
        font-size: 13px;
    }

    .frozenBtn>button {
        width: 100px;
    }

    .returnBtn>button {
        width: 100px;
    }

    /* change password */


    /* .sidebar.close~.change-password-hero {
    left: 100px;
    width: calc(100% - 100px);
  } */
    .change-password_form {
        width: 80%;
    }

    /* classlist */
    .classlist-reserve_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .reserve-number_bg {
        width: 18%;
        padding: 2% 2%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}